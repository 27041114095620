<template>
  <div class="mb-20">
    <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
      <div class="flex flex-wrap items-center col-span-1">
        <h2 class="mr-3 text-2xl font-bold">All Users in this category</h2>
      </div>

      <div>
        <button
          class="btn btn-sm btn-blue"
          @click="$refs.sendNotificationModal.open()"
        >
          Send Message
        </button>
        <button
          class="btn btn-sm btn-yellow ml-3"
          @click="$refs.uploadUsersModal.open()"
        >
          Upload Users
        </button>
      </div>
    </div>

    <div class="border border-blue-200 border-solid">
      <div class="flex">
        <div class="duration-300 w-full">
          <datatable
            :url="url"
            :ajax="true"
            :ajaxPagination="true"
            :index="true"
            :reverse-index="true"
            :data="users.data.users"
            dropdown="actions"
            :pageDetails="true"
            :columns="columns"
            :actions="actions"
            :query="searchQuery"
            :loading="users.loading"
            ref="table"
          />
        </div>
      </div>
    </div>
    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="sendNotificationModal"
    >
      <h3 class="font-bold mb-5 text-xl">Send Push Notification Message</h3>
      <div>
        <template v-if="getFormError(notificationForm)">
          <div class="alert alert-red mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(notificationForm) }}</span>
          </div>
        </template>
      </div>
      <form
        @submit.prevent="sendPushNotification"
        class="flex flex-col flex-grow"
      >
        <form-group
          v-model="notificationForm.data.title.value"
          :form="notificationForm"
          name="title"
          type="text"
        >
          Title
        </form-group>
        <form-group
          v-model="notificationForm.data.message.value"
          :form="notificationForm"
          name="message"
          type="textarea"
        >
          Message
        </form-group>

        <form-group
          v-model="notificationForm.data.cta.value"
          :form="notificationForm"
          name="title"
          type="text"
        >
          CTA Button Text
        </form-group>
        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.sendNotificationModal.close()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="notificationForm.loading"
          >
            <sm-loader-white v-if="notificationForm.loading" />
            <span v-else>Send Message</span>
          </button>
        </div>
      </form>
    </modal>

    <modal className="w-11/12 sm:w-400px rounded" ref="uploadUsersModal">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">Upload Users</h1>

        <div class="mt-10">
          <template v-if="getFormError(uploadUsersForm)">
            <div class="alert alert-red mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(uploadUsersForm) }}</span>
            </div>
          </template>

          <div class="flex flex-col">
            <dropzone
              class="mb-0 mr-2 w-full"
              v-model="uploadUsersForm.data.file.value"
              accept=".xlsx"
            ></dropzone>
            <span class="text-sm text-onboarding-100"
              >Only reads user_id row</span
            >
            <div
              class="form-group-2-text text-red-500 mb-2"
              v-if="getFirstError('file', uploadUsersForm)"
            >
              {{ getFirstError('file', uploadUsersForm) }}
            </div>

            <button
              type="button"
              class="btn btn-blue mt-32"
              @click.prevent="submitUploadUsersForm"
              :disabled="uploadUsersForm.loading"
            >
              <sm-loader
                v-if="uploadUsersForm.loading"
                class="sm-loader-white"
              />
              <span v-else>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: `${
        this.$baseurl
      }/push-notifications/categories/${this.categoryId()}`,
      columns: [
        {
          name: 'name',
          th: 'Name',
          render: (user) => user.name + ' ' + user.last_name,
        },
        {
          name: 'email',
          th: 'Email Address',
        },
      ],
      actions: [
        {
          text: 'Remove',
          class: 'btn btn-green btn-sm',
          action: this.removeFromCategory,
        },
      ],
      users: this.$options.resource([]),
      notificationForm: this.$options.basicForm([
        { name: 'title', rules: 'required' },
        { name: 'message', rules: 'required' },
        { name: 'cta', rules: 'nullable' },
      ]),
      uploadUsersForm: this.$options.basicForm([{ name: 'file', value: null }]),
      searchQuery: '',
    };
  },

  methods: {
    categoryId() {
      return this.$route.params.id;
    },
    async sendPushNotification() {
      if (!this.validateForm(this.notificationForm)) return;
      this.notificationForm.loading = true;
      this.notificationForm.error = null;
      await this.sendRequest('admin.pushNotifications.sendPushNotification', {
        data: {
          category_id: this.categoryId(),
          ...this.getFormData(this.notificationForm),
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'Push Notification has been sent successfully',
            button: 'Okay',
          });
          this.notificationForm = this.resetForm(this.notificationForm);
          this.$emit('success');
          this.$refs.sendNotificationModal.close();
        },
        error: (error) => {
          this.notificationForm.error = error;
          this.mapFormErrors(
            this.notificationForm,
            error.response?.data?.errors
          );
        },
      });
      this.notificationForm.loading = false;
    },

    async submitUploadUsersForm() {
      if (!this.validateForm(this.uploadUsersForm)) {
        return false;
      }
      const data = new FormData();
      data.append('file', this.uploadUsersForm.data.file.value);
      data.append('category_id', this.categoryId());
      this.uploadUsersForm.loading = true;
      this.uploadUsersForm.error = false;
      await this.sendRequest('admin.pushNotifications.uploadUsers', {
        data: data,
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'Users has been successfully uploaded to the category',
            button: 'Okay',
          });
          this.uploadUsersForm = this.resetForm(this.uploadUsersForm);
          this.$refs.uploadUsersModal.close();
          this.reloadTable();
        },
        error: (error) => {
          this.uploadUsersForm.error = error;
          this.mapFormErrors(
            this.uploadUsersForm,
            error?.response?.data?.errors
          );
        },
      });
      this.uploadUsersForm.loading = false;
    },

    async removeFromCategory(user) {
      await this.sendRequest(
        'admin.pushNotifications.removeFromCategory',
        user.id,
        {
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: 'User has been removed from the category',
              button: 'Okay',
            });
            this.reloadTable();
          },
          error: (error) => {
            console.log(error);
          },
        }
      );
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
  },
};
</script>
